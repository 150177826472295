.btn-submit {
  .spinner-border {
    margin: 0 10px 0 0;
  }

  &:not(.loading) {
    .spinner-border {
      display: none;
    }
  }
}