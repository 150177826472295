.modal-body {
  .page-loader-spinner {
    width: 10rem;
    height: 10rem;
    border-width: .50rem;
    animation-duration: 1.5s;
    margin: 20px auto;
    display: block;
  }

  .page-content-loader-label {
    position: absolute;
    top: 105px;
    left:50%;
    transform: translateX(-50%);
    font-weight: bold;
    font-size: 20px;
    color: darken(#007bff, 20%);
  }
}