.optional {
  .card {
    margin-bottom: 1rem;

    .full-width {
      width: 100%
    }

    label.option-button {
      width: 100%;
    }
  }
}