.btn.back-to-top {
  width: 35px;
  padding: 3px 3px 6px 3px;
  transition: opacity .5s linear;
  opacity: 0;
  cursor: default;

  &.back-to-top-visible {
    opacity: 1;
    cursor: pointer;
  }
}