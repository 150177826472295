.nav-pills {
  .nav-item {
    .tab-nav-bar-item {
      border-radius: 0;
      outline: none;
      box-shadow: none !important;
    }

    &:first-child {
      .tab-nav-bar-item {
        border-radius: .25rem 0 0 .25rem;
      }
    }

    &:last-child {
      .tab-nav-bar-item {
        border-radius: 0 .25rem .25rem 0;
      }
    }
  }
}