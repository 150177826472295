@import "../vars";

.form-check.inline {
  display: inline-block;
  padding: 0;
  margin: 0 2px 0 0;
}

.form-check {
  input[type="radio"] {
    display: none;

    + label {
      margin-bottom: 0;
    }
  }

  input[type="radio"]:checked {
    + label {
      color: #fff;
    }
  }
}

.single-button-style.btn-group {
  display: block;
  > .btn.option-button.form-check-label {
    border-radius: .25rem;
    margin-right: 0.3rem;
    margin-bottom: 0.4rem;
    margin-left: 0;
    padding: .375rem .75rem;
    box-shadow: none !important;

    &.active {
      color: #fff;

      &.btn-outline-primary {
        color: #0070e5;
        background: #cce5ff;
      }
    }
  }
}