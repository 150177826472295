@import "vars";
.modal__button.btn {
  padding: 1px 12px;
  svg, i {
    padding-top: 0.5rem;
    font-size: 1.8rem;
    padding-bottom: 0.5rem;
    color: $elementColor;
    transition: 0.3s linear all;
  }
  &:hover {
    svg, i {
      color: $elementColorHover;
      transform: rotate(5deg);
    }

  }
}

.modal__button__label {
  text-align: center;
  color: $fontColor;
}