@import "../vars";

.error-highlighting {
  label {
    color: $errorColor
  }
  select {
    border: 1px solid $errorColor;

    &:focus {
      border: 1px solid $errorColor;
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3);
    }
  }
}