@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:200;src:url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.eot');src:local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.woff2') format('woff2'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.woff') format('woff'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.ttf') format('truetype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-200.svg#SourceSansPro') format('svg')}@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.eot');src:local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.woff2') format('woff2'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.woff') format('woff'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.ttf') format('truetype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-regular.svg#SourceSansPro') format('svg')}@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.eot');src:local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.woff2') format('woff2'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.woff') format('woff'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.ttf') format('truetype'), url('../font/source-sans-pro-v11-latin-ext_latin/source-sans-pro-v11-latin-ext_latin-600.svg#SourceSansPro') format('svg')}


body
{
background-color: #F2F2F4;
color: #4b4b4b;
font-family: 'Source Sans Pro', sans-serif;
font-weight: 300;
font-size: 16px;
font-weight: normal;
overflow-x: hidden;
}



.objekt-pic-section 
{
padding-top: 370px;
}



.nextheader-objekt-pic-section
{
padding-top: 2em;
min-height: 190px;	
}

.nextheader-objekt-pic-section div
{
margin-left: auto;
margin-right: auto;	
width: 160px;
height: 160px;
border: 2px solid #d5d5d5;
border-radius: 200px;
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: cover;
background-color: #d6d6d6;
box-shadow: 1px 1px 5px 10px rgba(255, 255, 255, 0.3);
}


header 
{
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: cover;
display: block;
position: relative;
margin: 0;
padding: 0;
background-color: #cdcdcd;
min-height: 370px;
}



.welcome
{
margin-top: 1rem;	
}

.spacer-top-1
{
margin-top: 1rem;	
}

.spacer-top-2
{
margin-top: 2rem;	
}

.spacer-top-3
{
margin-top: 3rem;	
}

.contentblock .card:not(.optional .card)
{
margin-bottom: 1rem;	
}

.contentblock .card .card-title
{
margin-bottom: 1.3rem;
}

.not-show
{
display: none;
}

.show-flex
{
display: flex;
}

.show-block
{
display: block;
}

.show-inline-block
{
display: inline-block;
}

/* index 3 */

.status-img
{
border-radius: 50%;
height: 80px;
width: 80px;
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: cover;  	
border: 1px solid rgba(0,0,0,.125)
}


.card-status-image
{
padding-right: 1rem;
}


.inline-block
{
display: inline-block;
}

.card h4
{
padding-bottom: 0;
margin-bottom: 0;	
padding-top: 0.4rem;
font-size: 1.3rem;
}

.card h4 i 
{
color: #007bff;	
}

.no-padding-bottom
{	
padding-bottom: 0;
}
	
	
	
.blue-on .card-body
{
background-color:#007bff; 
}	


.blue-on 
{
border-color: #007bff;
}

	
.blue-on h4, .blue-on h4 i, .blue-on .fs-5
{
color: #fff; 	
}
	


.blue-on .btn-show-more
{
border-color: #fff;
background-color: #2b91ff;	
}


ul.list-group-shaded li:nth-child(2n)
{
background-color: #f3f3f3;	
}


.qrcode
{
border: 3px dashed #000;
border-radius: 15%;	
}



.clear 
{
clear: both;	
}

.option-button
{
border-color: #0070e5;
color:  inherit;
background-color: #cce5ff;
border-color:  #cce5ff;	
border-width: 2px;
font-weight: 400;
border-width: 2px;
margin-right: 0.2rem;
margin-bottom: 0.4rem;
}


.option-button.btn-selected
{
color:  #0070e5;
border-color: #0070e5;
}

.option-button.btn-selected:hover, .option-button.btn-selected:focus
{
color: #fff;
}

.option-button.btn-outline-primary:disabled
{
border-color: inherit;	
}

.optional  .option-button
{
width: 100%;
margin-bottom: 0;	
}

.jumbotron
{
padding: 2rem;	
}


.double_underline
{
border-bottom: double 3px #000000;	
}


.payoption
{
visibility: hidden;
opacity: 0.0;
}


.payoptionon
{
visibility: visible;
animation: AnimHinweisfenster 0.1s ease-in 2s both;

animation: fadeIn ease 2s;
opacity: 1;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}





.sub-navig
{
margin-bottom: 1rem;	
}

.progress
{
margin-top: 0.5rem;	
}

header h2, header h3
{
text-align: center;
}

header h2 span, header h3 span
{

background-color:rgba(255, 255, 255, 0.70);
padding-left: 0.5rem;
padding-right: 0.5rem;
}



header h2
{
margin-top: 1.2rem;
}

header h3
{
font-size: 1.5rem;
padding-bottom: 2.2rem;
padding-bottom: 5rem;
}

.hr-margin
{
margin-top: 0.5rem;
margin-bottom: 0.5rem;	
}

.text-col-middle
{
padding-top: 0.4rem;	
}

.payment-tab
{
margin-bottom: 1rem;	
}

.card-navig a
{
color: inherit;
text-decoration: none;
}


.card-navig button
{
-webkit-appearance: none;
-moz-appearance:    none;
appearance:         none;
background-color: transparent;
border:0;
color: #4b4b4b;
font-family: 'Source Sans Pro', sans-serif;
	
}


.optional
{
margin-left: -10px;
margin-right: -10px;	
}

.optional i
{
margin-right: 0.5rem;	
} 

.optional h4.inline-block
{
hyphens: auto;
}


.card-navig div
{
text-align: center;	
}

.card-navig i
{
	padding-top: 0.5rem;
font-size: 1.8rem;
padding-bottom: 0.5rem;	
color: #007bff;
}

.card-navig i:hover
{
color: #0059b7;
transform: rotate(5deg);
}


.spacer {margin-top: 0.5rem;} 

.row-flex
 {
display: flex;
flex-wrap: wrap;
}

.row-flex > .col-sm-6
{
margin-bottom: 1rem;
padding-left: 10px;	
padding-right: 10px;
}


.flex-height 
{
height: 100%;
}

.back-to-top 
{
position: fixed;
bottom:20px;
right:20px;
z-index: 100;
}

.back-to-top-chat
{
  bottom: 100px;
}


