@import "../vars";

.form-group {
  .form-check {
    .form-check-input {
      width: 20px;
      height: 20px;
      margin-top: 0.2rem;

      + label {
        padding: 0 0 0 5px;
      }

      &:required {
        + label {
          color: $errorColor;

          &:after {
            content: "*";
            padding: 0 0 0 5px;
            color: $errorColor;
          }
        }
      }

      &:checked {
        + label {
          color: initial;
        }
      }
    }
  }
}