@import "vars";

header {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #cdcdcd;
  min-height: 370px;
}


.header__logo-container {
  padding-top: 2em;
  min-height: 190px;
}

.header__logo-container__logo {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border: 2px solid #d5d5d5;
  border-radius: 200px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 160px;
  background-color: #d6d6d6;
  box-shadow: 1px 1px 5px 10px rgba(255 ,255, 255, 0.3)
}

.header__h2 {
  margin-top: 1.2rem;
  text-align: center;
}

.header__light-box {
  background-color: rgba(255, 255, 255, 0.70);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}