.bank-data-row {
  margin-bottom: 1rem;
}

.epc-qr-code-container {
  background: #fff;
  padding: 15px 15px 10px 15px;
  border-radius: 25px;
  border: 3px dashed #000;
  display: inline-block;
}