@import "../vars";

.form-check.inline {
  display: inline-block;
  padding: 0;
  margin: 0 2px 5px 0;
}

.form-check.check-box-container {
  input[type="checkbox"] {
    display: none;

    + label {
      margin-bottom: 0;
    }
  }

  input[type="checkbox"]:checked {
    + label {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }
  }
}