@import "display.css";
@import "components/form/select";
@import "components/vars.scss";

html {
  font-size: 16px;
}

.required {
  padding: 0 0 0 5px;
  color: $errorColor;
}

.required-info {
  span:first-child {
    font-size: 24px;
    line-height: 15px;
    vertical-align: top;
    display: inline-block;
    padding-top: 10px;
  }

  span:last-child {
    vertical-align: top;
    display: inline-block;
  }
}

body {
  padding: 0;
  margin: 0;
  background-color: #F2F2F4;
  color: #4b4b4b;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 16px;
  font-weight: normal;
  overflow-x: hidden;
}

.blue-on {
  .cancellation-bubble {
    opacity: 0.7;
  }

  .cancelActive {
    opacity: 1;
    margin: -7px -15px;
    padding: 7px 15px;
    background-color: $elementColor;
    color: #fff;
    border-radius: 5px;
  }
  .active {
    opacity: 1;
    border-radius: 5px;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.5rem;
  padding-bottom: 2.2rem;
  padding-bottom: 5rem;
  margin: 0;
}

.not-readable {
  filter: blur(15px);
}