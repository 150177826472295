.accordion {
  margin-bottom: 1rem;

  .card-body {
    padding: 0;
    border: 0;

    .card-header {
      background-color: transparent;
      padding: calc(.75rem + 6px) 1.25rem;
    }

    .badge {
      margin: 0 10px 0 5px;
    }

    h4 {
      display: inline-block;

      i {
        margin: 0 10px 0 0;
      }
    }
  }
}